import React from 'react';

const Container = ( props ) => (
  <div className={props.containerClassName ? props.containerClassName : 'container'}>
    <div className={props.rowClassName ? props.rowClassName : 'row'}>
      {props.children}
    </div>
  </div>
);

export default Container
