import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../../Layout';
import PostList from '../PostList';
import ReactPaginate from 'react-paginate';
import CategoryWidget from '../../components/widgets/CategoryWidget';
import RecentPostsWidget from '../../components/widgets/RecentPostsWidget';
import RecentComments from '../../components/widgets/RecentComments';
import Container from '../../../components/Container';
import SEO from '../../../components/SEO';

const Category = ( props ) => (
  <Layout appCustomClass='app-blog app-category' mainCustomClass='blog-wrapper'>
    <SEO seo={props.data.wpCategory && props.data.wpCategory.seo && props.data.wpCategory.seo} />
    <div className="blog-wrapper__breadcrumbs">
      <h3>Category: {props.pageContext.categoryName} { props.pageContext.pageNumber !== 0 && `/ page ${props.pageContext.humanPageNumber}` }</h3>
    </div>
    <Container containerClassName='blog-container'>
      <main>
        <PostList postList={props.pageContext.posts.slice(props.pageContext.skip, props.pageContext.skip + props.pageContext.limit)}/>
        {
          props.pageContext.numberOfPages > 1 &&
          <ReactPaginate
            disableInitialCallback
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={props.pageContext.numberOfPages}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            initialPage={props.pageContext.pageNumber}
            onPageChange={({ selected }) => {
              const page = selected + 1;
              const path = page === 1 ? `/category/${props.pageContext.categorySlug}` : `/category/${props.pageContext.categorySlug}/page/${page}/`;
              navigate(path);
            }}
          />
        }
      </main>
      <aside>
        <CategoryWidget categories={props.pageContext.widgetCategory} />
        <RecentPostsWidget
          recentPosts={props.pageContext.widgetRecentPosts}
          title={props.pageContext.widgetRecentPostsTitle}
        />
        <RecentComments
          comments={props.pageContext.widgetRecentComments}
        />
      </aside>
    </Container>
  </Layout>
);

export default Category;

export const pageQuery = graphql`
  query CategoryPageQuery($categorySlug: String!) {
    wpCategory(slug: {eq: $categorySlug}) {
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
        opengraphImage {
          localFile {
            publicURL
          }
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`;
