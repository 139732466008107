import React from 'react';

const StarRating = ({ rating, text = 'Rating: ' }) => {
  if ( rating === null || (rating < 0 && rating > 5) ) {
    return null;
  }

  return (
    <div className="post-rating__wrapper">
      <h5 className="post-rating__text">{text}</h5>
      <div className="post-rating" data-rating={rating} style={{
        '--rating': rating
      }} />
      <h5 className="post-rating__numbers">({rating}/5)</h5>
    </div>
  );
};

export default StarRating;
