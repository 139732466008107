import React from 'react';

const RecentComments = ({ comments }) => {

  if ( !Array.isArray(comments) || comments.length === 0 ) {
    return null;
  }

  return (
    <div className="widget widget__recent-comments">
      <h4 className="widget__title">
        Recent Reviews
      </h4>
      <ul className="comments-list">
        {comments && comments.map(comment => (
          <li key={comment.id} className="comments-list__item">
            {comment.author_name && <h6>{comment.author_name}</h6>}
            {
              comment.content && comment.content.rendered &&
              <div dangerouslySetInnerHTML={{
                __html: comment.content.rendered
              }} />
            }
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentComments;
