import React from 'react';
import ReplyForm from '../ReplyForm';

const Comment = ({
  id,
  postId,
  authorName,
  authorAvatarSrc,
  content,
  date,
  commentIdForReply,
  setCommentIdForReply
}) => {

  const openReplyCallback = () => {
    setCommentIdForReply(id);
  };
  const closeReplyCallback = () => {
    setCommentIdForReply(0);
  };

  return (
    <div className={`comment comment-${id}`} id={`comment-${id}`}>
      {
        authorAvatarSrc &&
          <div className="comment__author-avatar">
            <img src={authorAvatarSrc} alt={`${authorName} avatar`}/>
          </div>
      }
      <div className="comment__content">
        <h6 className="comment__author">{authorName}</h6>
        <time className="comment__date" dateTime={date}>{date}</time>
        {
          content &&
          <div
            className="comment__text"
            dangerouslySetInnerHTML={{
              __html: content
            }}
          />
        }
        <button className="comment__reply" type="button" onClick={openReplyCallback}>Reply</button>
      </div>
      {
        commentIdForReply === id &&
        <ReplyForm
          postId={postId}
          replyCommentId={id}
          isReplyTo={authorName}
          cancelReplyHandler={closeReplyCallback}
        />
      }
    </div>
  )
};

export default Comment;
