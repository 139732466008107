import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import StarRating from '../StarRating';
import { formatPostDate } from '../../../helpers/format';

const Post = ({
  title,
  date,
  authorName,
  categories,
  featuredImage,
  featuredImageAltText,
  content,
  isListing = true,
  isDisplayMeta = true,
  uri,
  commentsCount,
  rating = null
}) => (
  <div className="post">
    {
      featuredImage &&
      <div className="post__featured-image__wrapper">
        <GatsbyImage
          image={featuredImage}
          alt={featuredImageAltText ? featuredImageAltText : title}
        />
      </div>
    }
    <h3 className="post__title">
      {isListing ? <Link to={`/post${uri}`}>{title}</Link> : title }
    </h3>
    <StarRating rating={rating} />
    {
      isListing &&
      <h5 className="post__comments-count">
        Reviews: {Number.isInteger(commentsCount) ? commentsCount : 0}
      </h5>
    }
    {
      isDisplayMeta &&
      <div className="post__meta">
        <p>
          By { authorName } {categories && 'in'} {
          categories && categories.map((category, index, list) => {
            if ( index === list.length - 1 ) {
              return <Link key={category.id} to={`/category/${category.slug}`}>{category.name}</Link>
            }
            return <Link key={category.id} to={`/category/${category.slug}`}>{category.name}, </Link>
          })
        }
          <time dateTime={date}>at {formatPostDate(date)}</time>
        </p>
      </div>
    }
    <div
      className="post__content"
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
    {
      isListing && <Link to={`/post${uri}`} className='post__read-more'>Read more</Link>
    }
  </div>
);

export default Post;
