import React from 'react';
import { Link } from 'gatsby';

const CategoryWidget = ({ categories }) => {

  if ( !Array.isArray(categories) || categories.length === 0 ) {
    return null;
  }

  return (
    <div className="widget widget__category">
      <h4 className="widget__title">
        Categories
      </h4>
      <ul className="category-list">
        {categories && categories.map(category => (
          <li key={category.id} className="category-list__item">
            <Link to={`/category/${category.slug}`}>
              {category.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryWidget;
