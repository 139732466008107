exports.components = {
  "component---libs-templates-all-countries-lottery-results-index-js": () => import("./../../../../../libs/templates/AllCountriesLotteryResults/index.js" /* webpackChunkName: "component---libs-templates-all-countries-lottery-results-index-js" */),
  "component---libs-templates-blog-category-index-js": () => import("./../../../../../libs/templates/Blog/Category/index.js" /* webpackChunkName: "component---libs-templates-blog-category-index-js" */),
  "component---libs-templates-blog-index-js": () => import("./../../../../../libs/templates/Blog/index.js" /* webpackChunkName: "component---libs-templates-blog-index-js" */),
  "component---libs-templates-blog-single-post-index-js": () => import("./../../../../../libs/templates/Blog/SinglePost/index.js" /* webpackChunkName: "component---libs-templates-blog-single-post-index-js" */),
  "component---libs-templates-countries-lottery-results-index-js": () => import("./../../../../../libs/templates/CountriesLotteryResults/index.js" /* webpackChunkName: "component---libs-templates-countries-lottery-results-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-result-select-js": () => import("./../../../src/pages/result-select.js" /* webpackChunkName: "component---src-pages-result-select-js" */)
}

