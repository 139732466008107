import React from 'react';
import Post from '../Post';

const PostList = ({ postList }) => {
  if ( !postList ) {
    return null;
  }

  return (
    <div className="post-list">
      {
        postList && postList.map(post => (
          <Post
            key={post.id}
            authorName={post.author?.node?.name}
            title={post.title}
            date={post.date}
            content={post.excerpt}
            uri={post.uri}
            categories={post.categories?.nodes}
            featuredImage={
              post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
            }
            featuredImageAltText={
              post?.featuredImage?.node?.altText
            }
            isDisplayMeta={false}
            commentsCount={post.commentCount}
            rating={post.ratingGroup?.averageRating}
          />
        ))
      }
    </div>
  );
};

export default PostList;
