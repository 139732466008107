import React, { useState } from 'react';
import Input from '../../../components/FormElements/Input';
import TextArea from '../../../components/FormElements/TextArea';
import Loader from '../../../components/Loader';
import { sendComment, handleCommentError } from '../../../api/wordpress';

const ReplyForm = ({
  postId,
  replyCommentId = 0,
  isReplyTo = '',
  cancelReplyHandler
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeName = name => {
    if (name.length < 200) {
      setName(name)
    }
  };

  const handleChangeEmail = email => {
    if (email.length < 200) {
      setEmail(email)
    }
  };

  const isFormValid = () => {
    return name.length && isEmailValid(email) && message.length;
  };

  const isEmailValid = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    sendComment(postId, message, name, email, replyCommentId).then(response => {
      resetForm();
      setError(null);
      if ( response.data && response.data.status && response.data.status === 'hold' ) {
        setInfo('Your comment is awaiting moderation.');
      } else {
        setError(handleCommentError(response));
      }
      // console.log(response);
      setLoading(false);
    }).catch(error => {
      setError(handleCommentError(error));
      setInfo(null);
      setLoading(false);
    });
  };

  return (
    <div className="reply-form-wrapper">
      {
        isReplyTo ?
          <h3>Reply to <span className="reply-form__reply-to">{isReplyTo}</span> <button type='button' className='reply-form__close-reply' onClick={cancelReplyHandler}>Cancel reply</button></h3>
          :
          <h3>Leave a reply</h3>
      }
      <form onSubmit={handleSubmit} className="reply-form">
        <div className="reply-form__inner">
          <div className="reply-form__message">
            <TextArea
              id='reply-form-message'
              name='message'
              placeholder='Comment*'
              onChange={setMessage}
              error={!message.length}
              value={message}
            />
          </div>
          <div className="reply-form__name">
            <Input
              type="text"
              id="reply-form__name"
              placeholder='Your name*'
              onChange={handleChangeName}
              value={name}
              error={!name.length}
              name='name'
            />
          </div>
          <div className="reply-form__email">
            <Input
              type="text"
              id="reply-form__email"
              placeholder='Your email*'
              onChange={handleChangeEmail}
              value={email}
              error={!isEmailValid(email)}
              name='email'
            />
          </div>
        </div>
        <button type="submit" className="reply-form__submit" disabled={!isFormValid()}>Post comment</button>
        <div className="reply-form__messages">
          {error &&
          <p className='reply-form__error'>{error}</p>}
          {info &&
          <p className='reply-form__info'>{info}</p>}
        </div>
        {loading && <Loader overlay={true} />}
      </form>
    </div>
  );
};

export default ReplyForm;
