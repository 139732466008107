import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Comment from '../Comment';
import ReplyForm from '../ReplyForm';
import ReactPaginate from "react-paginate";
import { formatCommentDate } from '../../../helpers/format';

const Comments = ({ comments, commentsCount, postId, currentPostUri, pagesCount, currentPage }) => {
  const [commentIdForReply, setCommentIdForReply] = useState(0);
  // const testPrint = param => console.log(param);

  const recursiveLayoutFormatter = ( comments, isChild = false ) => {
    const layout = [];
    for (const comment of comments) {
      layout.push(
        <Comment
          key={comment.id}
          id={comment.id}
          postId={comment.post}
          date={formatCommentDate(comment.date)}
          content={comment.content && comment.content.rendered && comment.content.rendered}
          authorName={comment.author_name}
          authorAvatarSrc={
            comment.author_avatar_urls &&
            comment.author_avatar_urls['96'] &&
            comment.author_avatar_urls['96']
          }
          commentIdForReply={commentIdForReply}
          setCommentIdForReply={setCommentIdForReply}
        />
      );
      if ( comment.hasOwnProperty('childs') ) {
        layout.push(
          <div className="comments-child" key={`child-${comment.id}-${comment.parent}`}>
            {recursiveLayoutFormatter(comment.childs, true)}
          </div>
        )
      }
    }

    return layout;
  };

  const printComments = comments => {
    return recursiveLayoutFormatter(comments);
  };

  return (
    <div className="comments" id='comments'>
      {/*{testPrint(comments)}*/}
      {
        commentsCount ?
          commentsCount === 1 ?
            <h3 className='comments__title'>1 Comment</h3> :
            <h3 className='comments__title'>{commentsCount} Comments</h3>
          :
          <h3 className='comments__title'>Please, leave a comment</h3>
      }
      {
        Array.isArray(comments) && comments.length > 0 &&
        printComments(comments)
      }
      {
        pagesCount > 1 &&
        <ReactPaginate
          disableInitialCallback
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pagesCount}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          initialPage={currentPage}
          onPageChange={({ selected }) => {
            setCommentIdForReply(0);
            const page = selected + 1;
            const path = page === 1 ?
              `/post${currentPostUri}#comments` :
              `/post${currentPostUri}comments/page/${page}/#comments`;
            navigate(path);
          }}
        />
      }
      {
        commentIdForReply === 0 && <ReplyForm postId={postId} />
      }
    </div>
  );
};

export default Comments;
