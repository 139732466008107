import axios from './axios-instance';

export const sendComment = (postId, message, name, email, parentId = 0) => {
  const params = {
    content: message,
    author_name: name,
    author_email: email,
    post: postId
  };

  if ( parentId !== 0 ) {
    params.parent = parentId;
  }

  return axios.post('/comment', params, {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
};

export const handleCommentError = (errorResponse) => {
  let errorMessage = 'Something went wrong. Try again later.';
  if (errorResponse && errorResponse.data && errorResponse.data.code && errorResponse.data.message) {
    errorMessage = errorResponse.data.message.replace(/&#8217;/g, '\'');
  }
  return errorMessage;
};
