import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../Layout';
import Container from '../../../components/Container';
// import CategoryWidget from '../../components/widgets/CategoryWidget';
import RecentPostsWidget from '../../components/widgets/RecentPostsWidget';
import Post from '../Post';
import Comments from '../Comments';
import SEO from '../../../components/SEO';

const SinglePost = ( props ) => {
  const post = props.data.wpPost;

  return (
    <Layout appCustomClass='app-blog app-single-post' mainCustomClass='blog-wrapper'>
      <SEO seo={post.seo} />
      {/*{testPrint(props)}*/}
      <Container containerClassName='blog-container'>
        <main>
          <Post
            uri={post.uri}
            categories={post.categories?.nodes}
            authorName={
              post.author?.node?.name
            }
            content={post.content}
            title={post.title}
            date={post.date}
            featuredImageAltText={
              post.featuredImage?.node?.altText
            }
            featuredImage={
              post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
            }
            isListing={false}
            isDisplayMeta={false}
            rating={post.ratingGroup?.averageRating}
          />
          <Comments
            comments={props.pageContext.comments}
            commentsCount={props.pageContext.commentsCount}
            postId={post.databaseId}
            currentPostUri={post.uri}
            currentPage={props.pageContext.commentsCurrentPage}
            pagesCount={props.pageContext.commentsPages}
          />
        </main>
        <aside>
          {/*<CategoryWidget categories={props.pageContext.widgetCategory} />*/}
          <RecentPostsWidget recentPosts={props.pageContext.widgetRecentPosts} />
        </aside>
      </Container>
    </Layout>
  )
};

export default SinglePost;

export const pageQuery = graphql`
  query PostPageQuery($id: String!) {
    wpPost(
      id: {eq: $id}
    ) {
      id
      slug
      title
      uri
      content
      date
      databaseId
      ratingGroup {
        averageRating
      }
      author {
        node {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
          id
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
            childImageSharp {
              id
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
        opengraphImage {
          localFile {
            publicURL
          }
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`;
