import React from 'react';
import { Link } from 'gatsby';

const RecentPostsWidget = ({ recentPosts, title = 'Recent posts' }) => {

  if ( !Array.isArray(recentPosts) || recentPosts.length === 0 ) {
    return null;
  }

  return (
    <div className="widget widget__recent-posts">
      <h4 className="widget__title">
        {title}
      </h4>
      <ul className="recent-posts-list">
        {recentPosts && recentPosts.map(post => (
          <li key={post.id} className="recent-posts-list__item">
            <Link to={`/post${post.uri}`}>{post.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentPostsWidget;
