import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ seo, customMeta = [] }) => {
  if ( !seo ) {
    return null;
  }
  const meta = [];
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  const originLocation = typeof window !== 'undefined' ? window.location.origin : '';
  if ( seo.metaDesc ) {
    meta.push({
      name: `description`,
      itemprop: 'description',
      content: seo.metaDesc
    });
  }
  meta.push({
    itemprop: 'name',
    content: seo.title
  });
  if ( seo.metaKeywords ) {
    meta.push({
      name: `keywords`,
      content: seo.metaKeywords
    });
  }
  if ( seo.metaRobotsNofollow && seo.metaRobotsNoindex ) {
    meta.push({
      name: 'robots',
      content: `index, follow`//`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`
    });
  }
  meta.push({
    name: 'og:locale',
    content: 'en_US'
  });
  seo.opengraphType && meta.push({
    property: 'og:type',
    content: seo.opengraphType
  });
  seo.opengraphTitle && meta.push({
    property: 'og:title',
    content: seo.opengraphTitle
  });
  seo.opengraphDescription && meta.push({
    property: 'og:description',
    content: seo.opengraphDescription
  });
  currentUrl && meta.push({
    property: 'og:url',
    content: currentUrl
  });
  seo.opengraphSiteName && meta.push({
    property: 'og:site_name',
    content: seo.opengraphSiteName
  });
  seo.opengraphPublishedTime && meta.push({
    property: 'article:published_time',
    content: seo.opengraphPublishedTime
  });
  seo.opengraphModifiedTime && meta.push({
    property: 'article:modified_time',
    content: seo.opengraphModifiedTime
  });
  if (
    seo.opengraphImage &&
    seo.opengraphImage.localFile &&
    seo.opengraphImage.mediaDetails &&
    seo.opengraphImage.localFile.publicURL &&
    seo.opengraphImage.mediaDetails.width &&
    seo.opengraphImage.mediaDetails.height
  ) {
    meta.push({
      property: 'og:image',
      content: `${originLocation}${seo.opengraphImage.localFile.publicURL}`
    });
    meta.push({
      property: 'og:image:width',
      content: seo.opengraphImage.mediaDetails.width
    });
    meta.push({
      property: 'og:image:height',
      content: seo.opengraphImage.mediaDetails.height
    });
  }
  seo.twitterTitle && meta.push({
    name: 'twitter:title',
    content: seo.twitterTitle
  });
  seo.twitterDescription && meta.push({
    name: 'twitter:description',
    content: seo.twitterDescription
  });
  meta.push({
    name: 'twitter:card',
    content: 'summary_large_image'
  });
  if (
    seo.twitterImage &&
    seo.twitterImage.localFile &&
    seo.twitterImage.localFile.publicURL
  ) {
    meta.push({
      property: 'twitter:image',
      content: `${originLocation}${seo.twitterImage.localFile.publicURL}`
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={seo.title}
      meta={meta.concat(customMeta)}
    />
  );
};

export default SEO;
