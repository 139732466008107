import React from 'react';
import Layout from '../Layout';
import Container from '../../components/Container';
import PostList from './PostList';
import ReactPaginate from "react-paginate";
import { navigate, graphql } from 'gatsby';
// import CategoryWidget from '../components/widgets/CategoryWidget';
import RecentPostsWidget from '../components/widgets/RecentPostsWidget';
import SEO from '../../components/SEO';

const Blog = props => (
  <Layout appCustomClass='app-blog' mainCustomClass='blog-wrapper'>
    <SEO seo={props.data?.wpPage?.seo} />
    <div className="blog-wrapper__breadcrumbs">
      <h2>Blog { props.pageContext.pageNumber !== 0 && `/ page ${props.pageContext.humanPageNumber}` }</h2>
    </div>
    <Container containerClassName='blog-container'>
      <main>
        <PostList postList={props.pageContext.posts.slice(props.pageContext.skip, props.pageContext.skip + props.pageContext.limit)}/>
        {
          props.pageContext.numberOfPages > 1 &&
          <ReactPaginate
            disableInitialCallback
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={props.pageContext.numberOfPages}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            initialPage={props.pageContext.pageNumber}
            onPageChange={({ selected }) => {
              const page = selected + 1;
              const path = page === 1 ? `/blog/` : `/blog/page/${page}/`;
              navigate(path);
            }}
          />
        }
      </main>
      <aside>
        {/*<CategoryWidget categories={props.pageContext.widgetCategory} />*/}
        <RecentPostsWidget recentPosts={props.pageContext.widgetRecentPosts} />
      </aside>
    </Container>
  </Layout>
);

export default Blog;

export const pageQuery = graphql`
  query BlogPageQuery {
    wpPage(slug: {eq: "blog"}) {
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
        opengraphImage {
          localFile {
            publicURL
          }
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`;
